  * {
    font-family: sans-serif;
    /* Change your font family */

  }

.highlight{
  color: rgb(9, 171, 34);
  font-weight: bold;
}
  table#validators tbody tr.error {
    background-color: #f3e2dd;
  }

  .table-container {
    display: flex;
    justify-content: center;
    /* Horizontally centers the table */
  }
  .tooltip:hover .tooltiptext.copied {
    background-color: #0da724; /* Green background color */
    color: white;
}
.heading {
  text-align: center;
  font-size: 30px;
  margin-bottom: 50px;
}

  #validators {
    border-collapse: collapse;
    margin: 25px 25px;
    font-size: 0.85em;
    min-width: 1400px;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }

  .InActive {
    color: crimson;
    font-weight: bolder;
  }

  .apy {
    text-decoration: none;
  }

  #validators thead tr {
    background-color: #FFFF00	;
    color: #180202;
    text-align: center;
    font-weight: bold;
  }

  #validators th,
  #validators td {
    padding: 12px 15px;
    text-align: center;
  }

  #validators tbody tr {
    border-bottom: 1px solid #dddddd;
  }

  .green {
    color: #0da724;
    font-weight: bold;
  }

  #validators tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  #validators tbody tr:last-of-type {
    border-bottom: 2px solid #76D7C4;
  }

  #validators tbody tr.active-row {
    font-weight: bold;
    color: #76D7C4;
  }

  .validator {
    font-weight: bolder;
  }

  .decorate {
    background-color: #CEE5D0;
  }

  .bold {
    font-weight: bolder;
  }

  .header1 {
    margin-top: 2em;
    text-align: center;
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: rgb(151, 248, 161);
    color: rgb(13, 13, 13);
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .network-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* Allow buttons to wrap to the next line if needed */
    margin-bottom: 20px;
    /* Spacing below the button group */
  }

  .network-buttons button {
    background-color: #f0f0f0;
    /* Light grey background */
    border: 1px solid #ccc;
    /* Grey border */
    padding: 8px 16px;
    /* Vertical and horizontal padding */
    margin: 5px;
    /* Spacing around each button */
    font-size: 14px;
    /* Font size */
    cursor: pointer;
    /* Hand cursor on hover */
    transition: background-color 0.3s ease;
    /* Smooth transition for background color changes */
  }

  .network-buttons button:hover {
    background-color: #e0e0e0;
    /* Slightly darker grey on hover */
  }

  .network-buttons button.active {
    background-color: #FFFF00	;
    /* Blue color when active */
    color: white;
    /* White text color when active */
    border-color: #ffffff;
    /* Darker blue border when active */
  }











  /* Existing styles */

/* Add a media query for screens that are 768px wide or less */
@media (max-width: 768px) {
  
  /* This will make the font smaller on small screens */
  * {
    font-size: 14px;
  }
  
  .table-container {
    display: block;
    overflow-x: auto; /* Allows table to scroll horizontally on small devices */
  }

  /* Adjust the table layout for smaller screens */
  table#validators {
    min-width: unset; /* Allow the table to shrink */
    width: 80%; /* Set table width to 100% of its container */
    box-shadow: none; /* Optional: Remove box-shadow for a flatter design */
  }

  /* Make the buttons stack vertically and occupy the full width */
  .network-buttons button {
    display: block; /* Stack buttons vertically */
    width: 95%; /* Full width */
    box-sizing: border-box; /* Include padding and border in the element's width */
  }
  
  /* Stacking table headers if necessary */
  #validators thead tr {
    display: block;
  }

  #validators th,
  #validators td {
    white-space: nowrap; /* Ensures content of table cells doesn't wrap */
  }

  #validators tbody tr {
    display: block;
  }
  
  /* Adjust the tooltip for smaller screens */
  .tooltip .tooltiptext {
    margin-left: unset; /* Center the tooltip */
    left: 10%; /* Adjust left position */
    right: 10%; /* Adjust right position */
    width: 80%; /* Make the tooltip width smaller */
  }

  /* Make headers smaller */
  .header1 {
    font-size: 1.2em;
  }

  /* Simplify the button group layout */
  .network-buttons {
    display: block; /* Stack the buttons vertically */
  }

  /* Reduce the size of paddings and margins for small screens */
  #validators th, #validators td {
    padding: 8px 10px; /* Smaller padding */
  }

  /* If the text size in the buttons is too large, reduce it */
  .network-buttons button {
    padding: 8px; /* Smaller padding */
    font-size: 12px; /* Smaller font size */
  }
  
  /* You might also want to scale down or reorganize other elements so that they fit well on small screens */
}

/* You can add more media queries for different screen sizes if needed */
